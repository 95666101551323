<template>
  <div class="mb-8">
    <section class="text-gray-700">
      <div class="container px-5 py-24 mx-auto">
        <div class="text-center mb-20">
          <h1
            class="
              sm:text-3xl
              text-2xl
              font-medium
              text-center
              title-font
              text-gray-900
              mb-4
            "
          >
            404 Not Found
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base mb-6">
            Sorry, the page you looking for does not exists it may be removed.
          </p>
          <router-link to="/" class="mt-6 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                Home
          </router-link>
        </div>
        
      </div>
    </section>
  </div>
</template>